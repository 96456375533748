$('.row-of-chips > div').scroll(function () {
	
	const chipsRow = $('.row-of-chips > div');
	
	chipsRow.each(function (){
		if (this.scrollLeft >= this.scrollWidth - this.offsetWidth - 10) {
			this.parentNode.classList.add("scroll-end");
		} else if (this.parentNode.classList.contains("scroll-end")){
			this.parentNode.classList.remove("scroll-end");
		}
		
		if (this.scrollLeft >  0) {
			this.parentNode.classList.add("scroll-start");
		} else if (this.parentNode.classList.contains("scroll-start")){
			this.parentNode.classList.remove("scroll-start");
		}
	})
});