import flatpickr from "flatpickr";

// Allow (but override) input to let flatpickr fields be 'required'
// See open issue: https://github.com/flatpickr/flatpickr/issues/892

var f = flatpickr(".datepicker", {
  enableTime: true,
  dateFormat: "Y-m-d H:i",
  allowInput: true,
  altInput: true,
});
if(f != null && f._input != null) f._input.onkeydown = () => false;

f = flatpickr(".dateonlypicker", {
  enableTime: false,
  dateFormat: "Y-m-d",
  allowInput: true,
  altInput: true,
});
if(f != null && f._input != null) f._input.onkeydown = () => false;