const initAutocomplete = () => {

    /* Hashtag lookup - append to input rather than overwriting it */
    $("div[data-controller='autocomplete'] input").on( "keyup", function() {
        var inputVal = this.value;
        var end = this.selectionStart;
        var start = inputVal.substr(0, end).lastIndexOf(' ') + 1;

        $(".autocomplete-item").each(function() {
            var item = $(this);
            item.attr("data-autocomplete-value", inputVal.slice(0, start) + item.text() + inputVal.slice(end));
        });
      });
};

export { initAutocomplete };