const closeNavBtn = $("#closeNav");
const closeNavBtnDiv = closeNavBtn.parent();
const openNavBtn = $("#openNav");
const scrollNavBtn = $("#scrollNav");
const scrollNavBtnDiv = scrollNavBtn.parent();
const navbar = document.querySelector(".navbar");
const navOpen = $("#nav_open");
var isNavOverlayOpen = false;

document.addEventListener("turbo:before-cache", function (e) {
  closeNav(true);
});

document.addEventListener("turbo:load", () => {
  // Navigation Panels (notifications and / or searchbar)
  $(".toggle-panel").each(function() {
    $(this).on("click", function(){
      this.classList.toggle("opened");
      this.parentElement.classList.toggle("opened");

      if ($(this).is(".search-icon")){
        $(".navbar__search #search-form").focus();
      }
    });
  })
});

// Close the nav overlay when clicking anywhere else on the screen
$(document).click(function (e) {
  var target = $(e.target);

  if (
    isNavOverlayOpen &&
    target.closest("#openNav").length == 0 &&
    target.parents("#nav_open").length == 0 &&
    target.closest("#scrollNav").length == 0
  ) {
    closeNav();
  } else if(($(".toggle-panel.opened")[0]|| $(".navbar__notifications.opened")[0]) && $(e.target).closest('.opened').length === 0 ) {  //piece of code that does the same thing for the searchbar and notifications panel
    $(".toggle-panel.opened, .navbar__notifications.opened").each(function() {
      this.classList.remove("opened");
      this.parentElement.classList.remove("opened");
    });  
  }
});

const openNav = () => {
  var width = screen.width > 375 ? "375px" : "100%";
  navOpen.width(width);
  closeNavBtn.show();
  scrollNavBtn.show();
  closeNavBtnDiv.toggleClass("navbtn-show").toggleClass("navbtn-hide");
  scrollNavBtnDiv.toggleClass("navbtn-show").toggleClass("navbtn-hide");
  isNavOverlayOpen = true;
};

const closeNav = (instantHide = false) => {
  navOpen.width("0%");
  isNavOverlayOpen = false;

  if (instantHide) {
    $("#scrollNavUpIcon").hide();
    closeNavBtn.hide();
    scrollNavBtn.hide();
  } else {
    closeNavBtnDiv.toggleClass("navbtn-show").toggleClass("navbtn-hide");
    scrollNavBtnDiv.toggleClass("navbtn-show").toggleClass("navbtn-hide");
  }
};

const scrollNav = () => {
  var maxScrollTop = navOpen[0].scrollHeight - navOpen.height();
  var targetScrollTop = navOpen.scrollTop() < maxScrollTop ? maxScrollTop : 0;
  $("#nav_open").animate({ scrollTop: targetScrollTop }, 800);
};

const onScroll = () => {
  var maxScrollTop = navOpen[0].scrollHeight - navOpen.height();

  if (navOpen.scrollTop() == maxScrollTop) {
    $("#scrollNavUpIcon").show();
    $("#scrollNavDownIcon").hide();
  } else {
    $("#scrollNavUpIcon").hide();
    $("#scrollNavDownIcon").show();
  }
};

$(navOpen).on("scroll", onScroll);

const addListenerToButton = (button, fn) => {
  button.on("click", () => {
    fn();
  });
};

const alterNavState = () => {
  if (navbar) {
    addListenerToButton(closeNavBtn, closeNav);
    addListenerToButton(openNavBtn, openNav);
    addListenerToButton(scrollNavBtn, scrollNav);
    closeNav(true);
  }
};

export default alterNavState;
