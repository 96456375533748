// Use the Embedly protocool to atuo-resize the height of compatible
// iFrames: https://docs.embed.ly/v1.0/docs/provider-height-resizing
function onMessage(message) {

    let {data} = message;
  
    // If message isn't valid JSON, it must not be our resize event.
    if (typeof data === "string") {
      try {
        data = JSON.parse(data);
      } catch (ignore) {
        return;
      }
    }
  
    // Make sure it's a resize event.
    if (data.context !== "iframe.resize") return;
  
    // Select all compatible iframes.
    const iframes = document.querySelectorAll(".embed");
  
    // Set the matching iframe’s height
    iframes.forEach(iframe => {
      if (message.source !== iframe.contentWindow) return;
      iframe.style.height = `${data.height + 10}px`;
    });
  }
  
  // Attach our listener for the message from the iframe
  addEventListener("message", onMessage);